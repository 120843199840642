import { Controller } from "@hotwired/stimulus"

const preventEventDefault = event => event.preventDefault()
const removeAttachment = event => event.attachment.remove()

const removeTrailingNewlines = (event) => {
  const element = event.currentTarget

  if (element.innerHTML.match(/<br><\/div>$/)) {
    element.innerHTML = element.innerHTML.slice(0, -10) + "</div>"
    removeTrailingNewlines(event)
  }
}

const removeTrailingWhitespace = (event) => {
  const element = event.currentTarget

  if (element.innerHTML.match(/&nbsp;<\/div>$/)) {
    element.innerHTML = element.innerHTML.slice(0, -12) + "</div>"
    removeTrailingWhitespace(event)
  } else if (element.innerHTML.match(/&nbsp; <\/div>$/)) {
    element.innerHTML = element.innerHTML.slice(0, -13) + "</div>"
    removeTrailingWhitespace(event)
  }
}

const removeTrailingWhitespaceAndNewLines = (event) => removeTrailingNewlines(event) && removeTrailingWhitespace(event)

export default class extends Controller {
  static values = {
    configure: {
      type: String,
      default: ""
    }
  }

  initialize() {
    this.checkRequiredContent = this.checkRequiredContent.bind(this)
  }

  connect() {
    this.configure()
    this.checkRequiredContent()  // Initial check for required content validity
  }

  configure() {
    this.element.setAttribute("autocomplete", "off")

    !this.configurationIncludes("enable_fileupload") && this.disableUploads()
    !this.configurationIncludes("disable_trim") && this.enableTrim()
    this.required && this.element.addEventListener("trix-change", this.checkRequiredContent)
  }

  configurationIncludes(setting) {
    return this.configureValue.includes(setting)
  }

  disableUploads() {
    // disable file attachments from being accepted
    this.element.addEventListener("trix-file-accept", preventEventDefault)

    // if somehow an attachment does get added, remove anyway
    this.element.addEventListener("trix-attachment-add", removeAttachment)
  }

  enableTrim() {
    this.element.addEventListener("blur", removeTrailingWhitespaceAndNewLines)
  }

  disconnect() {
    this.element.removeEventListener("trix-file-accept", preventEventDefault)
    this.element.removeEventListener("trix-attachment-add", removeAttachment)
    this.element.removeEventListener("blur", removeTrailingWhitespaceAndNewLines)
    this.element.removeEventListener("trix-change", this.checkRequiredContent)
  }

  checkRequiredContent() {
    if (!this.required) return

    if (this.editorContent.length > 0) {
      this.element.classList.remove("trix-invalid")
    } else {
      this.element.classList.add("trix-invalid")
    }
  }

  get editorContent() {
    return this.element.editor.getDocument().toString().trim()
  }

  get required() {
    return this.element.getAttribute("required") == "required"
  }
}